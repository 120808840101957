import React from "react"

// UI
import SectionMediaFull from "~components/SectionMediaFull"

// Media
import imgPreviewAccountDetail from "~images/platform/preview-account-detail.png"

const GetStartedCloser: React.FC = () => (
  <SectionMediaFull
    title={
      <>
        <span className="block text-base md:text-lg text-blue-500 mb-2">CanopyOS</span> An Operating System for
        Redefining Loan Management
      </>
    }
    img={imgPreviewAccountDetail}
    className="-mb-8"
    narrow
  />
)

export default GetStartedCloser
