import React from "react"

// UI
import SEO from "~components/seo"
import FormPanel from "~content/get-started/FormPanel"
import SectionFooter from "~components/SectionFooter"
import GetStartedHero from "~content/get-started/Hero"
import GetStartedTestimonial from "~content/get-started/Testimonial"
import GetStartedFeaturesList from "~content/get-started/FeaturesList"
import GetStartedMedia from "~content/get-started/Media"
import GetStartedUseCases from "~content/get-started/UseCases"
import GetStartedCloser from "~content/get-started/Closer"
import SimpleHeader from "~components/Header/SimpleHeader"

// Media

const GetAccessPage = (props: { location: Location }): JSX.Element => {
  return (
    <>
      <SEO
        canonicalTag="https://canopyservicing.com/start/"
        description="Connect with us to learn how Canopy's platform can lift your financial product suite to the next level."
        title="Get Started | Canopy Servicing"
      />
      <div className="fs-unmask">
        <div className="grid md:grid-cols-7">
          <div className="relative md:order-last md:min-h-screen md:col-span-3 lg:col-span-2">
            <FormPanel location={props.location.hash} />
          </div>
          <div className="overflow-hidden flex flex-col md:col-span-4 lg:col-span-5">
            <SimpleHeader className="hidden md:block" />
            <main className="container mx-auto">
              <GetStartedHero />
              <GetStartedTestimonial />
              <GetStartedFeaturesList />
              <GetStartedMedia />
              <GetStartedUseCases />
              <GetStartedCloser />
            </main>
          </div>
        </div>
      </div>
      <SectionFooter />
    </>
  )
}

export default GetAccessPage
