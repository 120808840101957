import React from "react"
import { Link } from "gatsby"

// UI
import Logo from "~components/Logo"

const SimpleHeader: React.FC<SimpleHeaderProps> = ({ className }: SimpleHeaderProps) => {
  return (
    <header
      className={
        "w-full bg-white dark:bg-gray-900 mx-auto py-2 md:py-3 pl-6 pr-3 lg:px-12 border-solid border-b border-gray-200 dark:border-gray-800 " +
        className
      }
    >
      <div className="lg:container flex flex-wrap items-center justify-between mx-auto lg:px-6 fs-unmask">
        <Link to="/" className="w-28 lg:w-32">
          <Logo className="w-full" />
        </Link>
      </div>
    </header>
  )
}

interface SimpleHeaderProps {
  className?: string
}

export default SimpleHeader
