import React from "react"

// UI
import Headline from "~ui/Headline"
import GetStartedForm from "~content/get-started/Form"
import SimpleHeader from "~components/Header/SimpleHeader"

const FormContent = () => (
  <div className="mx-auto sm:max-w-xl xl:max-w-md px-6 md:px-6 xl:px-6 pt-8 md:pt-4 pb-12 md:pb-8">
    <div data-sal="slide-up">
      <Headline level={4} textSize="text-3xl md:text-4xl" className="text-white dark:text-black mb-0">
        Let&#39;s Chat!
      </Headline>
      <p className="text-gray-300 mb-6">Launch your next lending product with Canopy</p>
    </div>
    <GetStartedForm />
  </div>
)

const SuccessMessage = () => (
  <div className="mx-auto sm:max-w-xl xl:max-w-md px-12 md:px-6 xl:px-6 pt-12 md:pt-8 pb-12">
    <Headline level={4} textSize="text-3xl md:text-4xl" className="text-white dark:text-black mb-4" data-sal="slide-up">
      Thank you for your information!
    </Headline>
    <p className="leading-5 text-white mb-6" data-sal="slide-up" data-sal-delay="200">
      We&#39;ll be in touch within 30 minutes during business hours. Can&#39;t wait? Send us an email at{" "}
      <strong>sales@canopyservicing.com</strong>.
    </p>
    <p className="leading-5 text-gray-400" data-sal="slide-up" data-sal-delay="400">
      In the meantime, explore what you can do with Canopy through our{" "}
      <a href="https://learn.canopyservicing.com/" target="_new" className="text-blue-500">
        Learn Center
      </a>
      .
    </p>
  </div>
)

const GetStartedFormPanel: React.FC<GetStartedFormPanelProps> = ({ location }: GetStartedFormPanelProps) => (
  <div className="md:min-h-screen md:sticky md:top-0 bg-gray-900 dark:bg-gray-800 flex flex-col justify-between justify-center border-b border-gray-700">
    <div>
      <SimpleHeader className="md:hidden" />
    </div>

    {location === "#submitted" ? <SuccessMessage /> : <FormContent />}

    <div>{/* Flexbox hack to center middle div */}</div>
  </div>
)

interface GetStartedFormPanelProps {
  location: string
}

export default GetStartedFormPanel
