import React, { useState } from "react"

// UI
import Input from "~ui/Input"
import Select from "~ui/Select"
import Button from "~ui/Button"

const GetStartedForm = (): JSX.Element => {
  const [loanProductStage, setLoanProductStage] = useState("")
  const params = new URLSearchParams(typeof window !== "undefined" ? window.location.search : "")
  const leadSource = params.get("utm_LeadSource")
  const leadSourceDetail = params.get("utm_Lead_Source_Detail__c")

  const handleChange = (event) => {
    setLoanProductStage(event.target.value)
  }

  return (
    <form
      data-sal="slide-up"
      data-sal-delay="200"
      action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
      method="POST"
    >
      <input type="hidden" name="oid" value="00D8c000002dqmW" />
      <input type="hidden" name="retURL" value="https://canopyservicing.com/start#submitted" />
      <input type="hidden" name="lead_source" id="lead_source" value={leadSource ? leadSource : "Website"} />
      <input
        type="hidden"
        name="00N8c00000g58e1"
        id="00N8c00000g58e1"
        value={leadSourceDetail ? leadSourceDetail : ""}
      />
      {/* 
      NOTE: These fields are optional debugging elements. Please uncomment 
      these lines if you wish to test in debug mode.
      <input type="hidden" name="debug" value="1" />
      <input type="hidden" name="debugEmail" value="canopyservicing@fastslowmotion.com" /> 
    */}
      <div className="grid grid-cols-6 gap-x-2 gap-y-4">
        <div className="col-span-3">
          <label
            className="block text-sm text-white dark:text-black dark:text-white font-semibold mb-2"
            htmlFor="first_name"
          >
            First Name
          </label>
          <Input
            maxLength={40}
            name="first_name"
            type="text"
            className="bg-gray-800 border-gray-900 text-white"
            required
          />
        </div>
        <div className="col-span-3">
          <label
            className="block text-sm text-white dark:text-black dark:text-white font-semibold mb-2"
            htmlFor="last_name"
          >
            Last Name
          </label>
          <Input
            maxLength={80}
            name="last_name"
            type="text"
            className="bg-gray-800 border-gray-900 text-white"
            required
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            className="block text-sm text-white dark:text-white dark:text-black dark:text-white font-semibold mb-2"
            htmlFor="email"
          >
            Business Email
          </label>
          <Input maxLength={80} name="email" type="email" className="bg-gray-800 border-gray-900 text-white" required />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            className="block text-sm text-white dark:text-black dark:text-white font-semibold mb-2"
            htmlFor="company"
          >
            Company
          </label>
          <Input
            maxLength={40}
            name="company"
            type="text"
            className="bg-gray-800 border-gray-900 text-white"
            required
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            className="block text-sm text-white dark:text-black dark:text-white font-semibold mb-2"
            htmlFor="00N8c00000h5vhe"
          >
            For B2B or B2C?
          </label>
          <Select
            id="00N8c00000h5vhe"
            name="00N8c00000h5vhe"
            defaultValue=""
            className="bg-gray-800 border-gray-900 text-white"
            required
          >
            <option value="" disabled>
              Select one...
            </option>
            <option value="B2B Lending">B2B Lending</option>
            <option value="B2C Lending">B2C Lending</option>
            <option value="Both">Both</option>
          </Select>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            className="block text-sm text-white dark:text-black dark:text-white font-semibold mb-2"
            htmlFor="00N8c00000h5vhZ"
          >
            Loan Product Stage
          </label>
          <Select
            id="00N8c00000h5vhZ"
            name="00N8c00000h5vhZ"
            value={loanProductStage}
            onChange={handleChange}
            className="bg-gray-800 border-gray-900 text-white"
            required
          >
            <option value="" disabled>
              Select one...
            </option>
            <option value="New Product Launch">New Product Launch</option>
            <option value="Scaling Pilot Product">Scaling Pilot Product</option>
            <option value="Enhancing Product Migration">Enhancing Product Migration</option>
            <option value="Existing Product Migration">Existing Product Migration</option>
            <option value="Other">Other</option>
          </Select>
        </div>
        {loanProductStage === "Other" && (
          <div className="col-span-6">
            <label
              className="block text-sm text-white dark:text-black dark:text-white font-semibold mb-2"
              htmlFor="00N8c00000h5vht"
            >
              Please describe your product stage <span className="text-gray-500 font-normal">(Optional)</span>
            </label>
            <Input
              maxLength={255}
              id="00N8c00000h5vht"
              name="00N8c00000h5vht"
              type="text"
              className="bg-gray-800 border-gray-900 text-white"
            />
          </div>
        )}
        <div className="col-span-6">
          <label
            className="block text-sm text-white dark:text-black dark:text-white font-semibold mb-2"
            htmlFor="description"
          >
            Tell us what you hope to accomplish <span className="text-gray-500 font-normal">(Optional)</span>
          </label>
          <Input maxLength={1000} name="description" type="text" className="bg-gray-800 border-gray-900 text-white" />
        </div>
        <div style={{ position: "absolute", left: "-9999px", top: "-9999px" }}>
          <label htmlFor="pardot_extra_field">Comments</label>
          <input type="text" id="pardot_extra_field" name="pardot_extra_field" />
        </div>
        <div className="col-span-6">
          <Button type="submit" name="submit" style="primary" className="w-full mt-4" />
        </div>
      </div>
    </form>
  )
}

export default GetStartedForm
