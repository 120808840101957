import React, { ChangeEvent } from "react"
import { FieldError } from "react-hook-form"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"

const Select: React.FC<SelectProps> = ({
  children,
  className,
  errors,
  defaultValue,
  disabled,
  onChange,
  name,
  title,
  ref,
  value,
  ...props
}: SelectProps) => {
  return (
    <div className="relative">
      {title && (
        <label htmlFor={name} className="block text-sm text-black dark:text-white font-semibold mb-2">
          {title}
          {errors && <span className="text-red-500 ml-2 font-normal">Required</span>}
        </label>
      )}
      <select
        className={`appearance-none	px-3 py-3 placeholder-gray-300 text-black dark:text-white relative bg-gray-50 dark:bg-gray-800 rounded-xl text-sm border outline-none focus:outline-none focus:ring focus:border-blue-400 dark:focus:border-blue-500 disabled:bg-gray-200 dark:disabled:bg-gray-700 w-full transition duration-200 ${
          errors ? "border-red-300 dark:border-red-800" : "border-gray-300 dark:border-gray-900"
        } ${className}`}
        id={name}
        name={name}
        value={value}
        defaultValue={defaultValue}
        ref={ref}
        disabled={disabled}
        onChange={onChange}
        {...props}
      >
        {children}
      </select>
      <div className="w-8 h-8 absolute bottom-1.5 right-1 flex items-center justify-center pointer-events-none">
        <Icon icon={faChevronDown} size="sm" className="text-gray-600" />
      </div>
    </div>
  )
}

interface SelectProps {
  children: React.ReactNode
  className?: string
  errors?: FieldError
  defaultValue?: string
  disabled?: boolean
  id?: string
  name: string
  onChange?: (event: ChangeEvent) => void
  ref?: string
  required?: boolean
  title?: string
  type?: string
  maxLength?: number
  value?: string
}

export default Select
