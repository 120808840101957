import React from "react"

// UI
import Headline from "~ui/Headline"
import SectionThreeCols from "~components/SectionThreeCols"
import SectionTwoCols, { TwoColCard } from "~components/SectionTwoCols"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faCircleCheck } from "@fortawesome/pro-thin-svg-icons"

import imgInstallmentLoans from "~images/use-case/installment-loans-hero.png"
import imgPaymentCards from "~images/use-case/charge-cards-hero.png"
import imgBNPL from "~images/use-case/buy-now-pay-later-hero.png"

const GetStartedUseCases: React.FC = () => (
  <>
    <SectionThreeCols title="Common Lending Use Cases" narrow />

    <SectionTwoCols className="mb-16 md:mb-24 lg:mb-40 sm:mt-8 lg:mt-16" narrow>
      <TwoColCard data-sal="slide-up" data-sal-delay="100">
        <Headline level={5} className="mb-2 md:mb-4 text-blue-500">
          Installment Loans
        </Headline>
        <Headline level={3} className="mb-6 md:mb-8">
          Create an installment lending solution
        </Headline>
        <ul className="2xl:text-lg">
          <li className="mb-3 flex">
            <Icon icon={faCircleCheck} size="lg" className="text-blue-500 mr-3" />
            <span className="dark:text-white">Streamlined dispute processing</span>
          </li>
          <li className="mb-3 flex">
            <Icon icon={faCircleCheck} size="lg" className="text-blue-500 mr-3" />
            <span className="dark:text-white">Dynamic, retroactive events</span>
          </li>
          <li className="flex">
            <Icon icon={faCircleCheck} size="lg" className="text-blue-500 mr-3" />
            <span className="dark:text-white">An immutable ledger</span>
          </li>
        </ul>
      </TwoColCard>
      <TwoColCard className="bg-purple-400 dark:bg-purple-500 md:order-first" data-sal="slide-up">
        <img src={imgInstallmentLoans} alt="Installment Loans" data-sal="slide-up" data-sal-delay="300" />
      </TwoColCard>
    </SectionTwoCols>

    <SectionTwoCols className="mb-16 md:mb-24 lg:mb-40" narrow>
      <TwoColCard data-sal="slide-up">
        <Headline level={5} className="mb-2 md:mb-4 text-blue-500">
          Payment Cards
        </Headline>
        <Headline level={3} className="mb-6 md:mb-8">
          Create a charge or revolving card product
        </Headline>
        <ul className="2xl:text-lg">
          <li className="mb-3 flex">
            <Icon icon={faCircleCheck} size="lg" className="text-blue-500 mr-3" />
            <span className="dark:text-white">Risk-based scoring</span>
          </li>
          <li className="mb-3 flex">
            <Icon icon={faCircleCheck} size="lg" className="text-blue-500 mr-3" />
            <span className="dark:text-white">Deferred interest capabilities</span>
          </li>
          <li className="flex">
            <Icon icon={faCircleCheck} size="lg" className="text-blue-500 mr-3" />
            <span className="dark:text-white">Define promo periods</span>
          </li>
        </ul>
      </TwoColCard>
      <TwoColCard className="bg-blue-300 dark:bg-blue-400" data-sal="slide-up" data-sal-delay="100">
        <img
          src={imgPaymentCards}
          alt="Payment Cards"
          className="filter drop-shadow-xl"
          data-sal="slide-up"
          data-sal-delay="300"
        />
      </TwoColCard>
    </SectionTwoCols>

    <SectionTwoCols className="mb-16 md:mb-24 lg:mb-40" narrow>
      <TwoColCard data-sal="slide-up">
        <Headline level={5} className="mb-2 md:mb-4 text-blue-500">
          Buy Now Pay Later
        </Headline>
        <Headline level={3} className="mb-6 md:mb-8">
          Create and manage a BNPL solution
        </Headline>
        <ul className="2xl:text-lg">
          <li className="mb-3 flex">
            <Icon icon={faCircleCheck} size="lg" className="text-blue-500 mr-3" />
            <span className="dark:text-white">Multiple loans within a single account</span>
          </li>
          <li className="mb-3 flex">
            <Icon icon={faCircleCheck} size="lg" className="text-blue-500 mr-3" />
            <span className="dark:text-white">Expand loan acceptance</span>
          </li>
          <li className="flex">
            <Icon icon={faCircleCheck} size="lg" className="text-blue-500 mr-3" />
            <span className="dark:text-white">Streamline merchant settlement</span>
          </li>
        </ul>
      </TwoColCard>
      <TwoColCard className="bg-yellow-300 dark:bg-yellow-400 md:order-first" data-sal="slide-up">
        <img
          src={imgBNPL}
          alt="Buy Now Pay Later"
          className="filter drop-shadow-xl"
          data-sal="slide-up"
          data-sal-delay="300"
        />
      </TwoColCard>
    </SectionTwoCols>
  </>
)

export default GetStartedUseCases
